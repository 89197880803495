import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import TelaGeral from './paginas/TelaGeral';
import { SttTranslateHook } from '@stt-componentes/core';
import { SttThemeProvider } from '@stt-componentes/core';

class App extends Component {
    render() {
        return (
            <SttThemeProvider config={global.gConfig}>
                <BrowserRouter basename={`/${global.gConfig.basename}`}>
                    <SttTranslateHook.I18nContextProvider modulo={'meusExames'}>
                        <Routes>
                            <Route path="/protocolo/:protocoloUrl" element={<TelaGeral />} />
                            <Route path="/" element={<TelaGeral />} />
                            <Route path="*" element={<Navigate to='/' replace />} />
                        </Routes>
                    </SttTranslateHook.I18nContextProvider>
                </BrowserRouter>
            </SttThemeProvider>
        );
    }

    getChildContext() {
        return {
            currentLanguage: this.props.currentLanguage,
            modulo: this.props.modulo,
        };
    }
}

App.childContextTypes = {
    currentLanguage: PropTypes.string.isRequired,
    modulo: PropTypes.string.isRequired
};

export default App;